import { Box, Input, Button, BoxProps } from "@chakra-ui/react";
import { navigate } from "gatsby";
import { FC, useEffect, useState } from "react";
import ShiIcon from "../ShiIcon";

const SearchInput: FC<
  {
    buttonLeft?: boolean;
    value?: string;
    onChange?: (q: string) => void;
    onSubmit?: (q: string) => void;
  } & BoxProps
> = ({
  placeholder,
  buttonLeft,
  value,
  onChange,
  onSubmit,
  ...rest
}) => {
  const [search, setSearch] = useState<string>(value || "");

  useEffect(() => {
    setSearch(value || "");
  }, [value]);

  useEffect(() => {
    onChange?.(search);
  }, [search]);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (onSubmit) {
      return onSubmit(search);
    }

    navigate(`/result/?q=${search}`);
  };

  return (
    <Box position="relative" {...rest}>
      <form onSubmit={handleSubmit}>
        <Input
          _focus={{ outline: "none" }}
          _hover={{ borderColor: "text-secondary" }}
          _placeholder={{
            color: "grey-600",
            fontSize: "font-15",
            lineHeight: "1.8",
          }}
          border="1px"
          borderColor="grey-200"
          borderRadius="full"
          color="text-primary"
          fontSize="font-15"
          h="space-48"
          lineHeight="1.8"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          p={buttonLeft ? "18px 24px 18px 48px" : "18px 48px 18px 24px"}
          placeholder={placeholder}
          required={false}
        />

        <Button
          _disabled={{ opacity: 1, cursor: "not-allowed" }}
          disabled={!search || search === ""}
          h="full"
          left={buttonLeft ? "0" : "unset"}
          pl={buttonLeft ? "space-24" : "unset"}
          position="absolute"
          pr={buttonLeft ? "unset" : "space-24"}
          right={buttonLeft ? "unset" : "0"}
          top="calc(100% - 48px)"
          type="submit"
          variant="reset"
        >
          <ShiIcon
            name="magnifier"
            sx={{
              circle: { stroke: "grey-600" },
              rect: { stroke: "grey-600" },
              path: { fill: "grey-600" },
            }}
          />
        </Button>
      </form>
    </Box>
  );
};

export default SearchInput;
