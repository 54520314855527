import { FC } from "react";
import { BlockContainer } from "src/atoms";
import { GatsbyImage } from "gatsby-plugin-image";
import Renderer from "src/lib/renderer";
import { heading2 } from "src/lib/renderer/elements/headings";
import paragraph from "src/lib/renderer/elements/paragraph";
import { Element } from "@prismicio/react";
import { useInView } from "react-intersection-observer";
import { calculateHardcodedTopValue, calculateMarginLeftProps, getColor, getItemWidthProps } from "./utils";
import {
  Box,
  Flex,
  Spacer,
  Text,
  useBreakpointValue
} from "@chakra-ui/react";
import RatingsGradeStars from "src/components/GradeStars";
import { SvgOrImg } from "src/components/SvgOrImg";
import { MAX_DESKTOP_ITEM_WIDTH, MIN_DESKTOP_ITEM_WIDTH } from "./constants";

const BlockScrollableCards: FC<Gatsby.PageBlockScrollableCardsFragment> = ({
  primary,
  items
}) => {
  if (!primary) throw Error();

  const {
    content
  } = primary;

  const isMobile = useBreakpointValue({
    base: true,
    xs: true,
    sm: true,
    md: false,
    lg: false,
    xl: false,
    "2xl": false,
    "3xl": false,
  });

  const isAtLeast2Xl = useBreakpointValue({
    base: false,
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false,
    "2xl": true,
    "3xl": true,
  }) || false;

  const isAtLeastLg = useBreakpointValue({
    base: false,
    xs: false,
    sm: false,
    md: false,
    lg: true,
    xl: true,
    "2xl": true,
    "3xl": true,
  }) || false;

  const itemsFirstGroupTemp = items?.length ? items.filter((i) => !i?.is_second_column) : [];
  const itemsSecondGroupTemp = items?.length ? items?.filter((i) => !!i?.is_second_column) : [];
  // TODO: LATER: CMS: allow not mobile
  const itemsFirstGroup = isMobile ? [...(itemsFirstGroupTemp || []).slice(1).reverse()] : itemsFirstGroupTemp;
  const itemsSecondGroup = isMobile ? [...(itemsSecondGroupTemp || [])].reverse().slice(1) : itemsSecondGroupTemp;

  const renderItemsGroup = (itemsGroup: Gatsby.PageBlockScrollableCardsFragment["items"], index: number) => {
    const isFirstGroup = index === 0;

    const absoluteProps = {
      top: { base: "inherit", md: `${calculateHardcodedTopValue(isFirstGroup, inView, isAtLeast2Xl, isAtLeastLg)}px` },
      position: { base: "inherit", md: "absolute" }
    }

    const mobileAlignmentProps = calculateMarginLeftProps(itemsGroup, isFirstGroup, inView);

    // TODO: LATER: CMS: allow transition
    return <Box
      {...absoluteProps}
      transition="top 600ms ease"
      marginBottom={{ base: "space-8", md: "0" }}
    >
      <Flex
        {...mobileAlignmentProps}
        transition="margin-left 600ms ease"
        display={{ base: "flex", md: "none" }}
      >
        {itemsGroup?.map((item) => renderItem(item))}
      </Flex>
      <Box
        display={{ base: "none", md: "block" }}
      >
        {itemsGroup?.map((item) => renderItem(item))}
      </Box>
    </Box>
  }

  const renderIcon = (image: Gatsby.Maybe<{
    readonly document: Gatsby.Maybe<Gatsby.ElementImageFixed80Fragment>;
  }>) => {
    const imageFile = image?.document?.data?.image_file;
    if (!imageFile) {
      return null;
    }

    return (
      <Box
        bg="grey-0"
        borderRadius="40px"
        px={{ base: "space-20", md: "space-24" }}
        py={{ base: "10px", md: "space-14" }}
        w={{ base: "58px", md: "72px" }}
      >
        <SvgOrImg
          imageFile={image?.document?.data?.image_file}
          isBox={true}
          size={isMobile ? 18 : 24}
          colorChanges={[["#25241D", "white"]]}
        />
      </Box>
    );
  }

  const renderImg = (image: Gatsby.Maybe<{
    readonly document: Gatsby.Maybe<Gatsby.ElementImageFixed80Fragment>;
  }>) => {
    const imageFile = image?.document?.data?.image_file;
    if (!imageFile) {
      return null;
    }

    return (
      <Box
        as={GatsbyImage}
        maxHeight="80px"
        image={imageFile.gatsbyImageData}
        alt={imageFile.alt ?? ""}
      />
    );
  };

  const renderItem = (item: NonNullable<Gatsby.PageBlockScrollableCardsFragment["items"]>[number]) => {
    if (!item) {
      return null;
    }

    const {
      image,
      is_icon: isIcon,
      background_image: backgroundImage,
      background_color: backgroundColor,
      title,
      description,
      rating
    } = item;

    const backgroundImageUrl = backgroundImage?.document?.data?.image_file?.gatsbyImageData?.images?.fallback?.src;
    const backgroundProps = !!backgroundImageUrl ? {
      backgroundImage: `url('${backgroundImageUrl}')`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center center"
    } : {
      bg: getColor(backgroundColor)
    }

    return (
      <Box
        {...backgroundProps}
        key={`${title}-${description}-${backgroundImageUrl}-${backgroundColor}-${rating}-${isIcon}`}
        height={{ base: "162px", md: "226px", lg: "266px", "2xl": "320px" }}
        borderRadius="lg"
        marginBottom={{ base: "0", md: "space-16" }}
        paddingX={{ base: "space-24", md: "space-40" }}
        paddingY={{ base: "space-24", md: "space-48" }}
        marginX={{ base: "space-4", md: 0 }}
        textAlign="left"
        color="grey-0"
        {...getItemWidthProps(isIcon, rating)}
      >
        <Flex
          flexDirection="column"
          height="100%"
        >
          {isIcon ? renderIcon(image) : renderImg(image)}
          {title ? <Text
            fontWeight="400"
            fontSize={{ base: "font-50", md: "font-58", lg: "64px", "2xl": "103px" }}
            lineHeight={{ base: 1.2, md: 1.2, lg: 1.5, "2xl": "123px" }}
          >
            {title}
          </Text> : null}
          <Spacer />
          <Box>
            {description ? <Text
              fontWeight="400"
              fontSize={{ base: "font-20", md: "font-18", lg: "font-24", "2xl": "font-28" }}
              lineHeight={{ base: "base", md: "1.286" }}
              marginBottom="0"
              paddingBottom="0"
            >
              {description}
            </Text> : null}
            {rating ? <RatingsGradeStars
              fill="grey-0"
              fillPlaceholder="greyLight-1"
              value={rating}
              spacing="0.375rem"
              size="space-16"
              marginTop={{ base: "space-4", md: "space-16" }}
              paddingTop="0"
            /> : null}
          </Box>
        </Flex>
      </Box>
    )
  };

  const { ref, inView
  } = useInView({
    initialInView: false,
    rootMargin: `4000px 0px -${isMobile ? 500 : (isAtLeast2Xl ? 688 : (isAtLeastLg ? 580 : 500))}px 0px`,
  });

  const itemsGroupWidthProps = {
    base: "100%",
    md: `${MIN_DESKTOP_ITEM_WIDTH}px`,
    lg: `${MAX_DESKTOP_ITEM_WIDTH}px`
  };

  return (
    <BlockContainer
      paddingX={{ base: 0, md: "space-58", "2xl": 0 }}
    >
      <Flex
        ref={ref}
        paddingBottom={{ base: "space-70", md: "0" }}
        paddingLeft={{ base: "space-0", md: "space-64" }}
        paddingRight={{ base: "space-0", md: "space-16" }}
        paddingTop={{ base: "space-70", md: "0" }}
        marginRight="0"
        w="full"
        bg={{ base: "grey-0", md: "blue-0" }}
        borderRadius={{ base: 0, md: "lg" }}
        flexDirection={{ base: "column", md: "row" }}
        height={{ base: "inherit", md: "500px", lg: "580px", "2xl": "688px" }}
        overflow="hidden"
        position="relative"
      >
        <Flex
          w={{ base: "100%", md: "50%" }}
          paddingRight="space-24"
          flexDirection="column"
          textAlign="left"
          paddingX={{ base: "space-16", md: "0" }}
        >
          <Spacer />
          <Renderer
            field={content}
            overrides={{
              [Element.heading2]: (args) =>
                heading2({
                  ...args,
                  overrideProps: {
                    marginBottom: { base: "space-8", md: "space-24" },
                    color: "white",
                    fontSize: { base: "font-30", lg: "font-40", "2xl": "48px" },
                    lineHeight: 1.2
                  },
                }),
              [Element.paragraph]: (args) =>
                paragraph({
                  ...args,
                  overrideProps: {
                    marginBottom: { base: "space-32", md: "space-24" },
                    color: { base: "greyLight-1", md: "white" },
                    fontSize: { base: "font-16", md: "font-18", "2xl": "font-20" },
                    lineHeight: "base"
                  },
                }),
            }}
          />
          <Spacer
          />
        </Flex>
        <Spacer
          minWidth={{ base: "space-16", "lg": "space-64" }}
        />
        <Flex
          flexDirection={{ base: "column", md: "row" }}
        >
          {itemsFirstGroup?.length ? <Box
            w={itemsGroupWidthProps}
            marginRight="space-8"
          >{renderItemsGroup(itemsFirstGroup, 0)}</Box> : null}
          {itemsSecondGroup?.length ? <Box
            w={itemsGroupWidthProps}
            marginLeft={{ base: "0", md: "space-8" }}
          >{renderItemsGroup(itemsSecondGroup, 1)}</Box> : null}
        </Flex>
      </Flex>
    </BlockContainer>
  )
};

export default BlockScrollableCards;
