import { FC, useState } from "react";
import { BlockContainer } from "src/atoms";
import Renderer from "src/lib/renderer";
import { heading2 } from "src/lib/renderer/elements/headings";
import paragraph from "src/lib/renderer/elements/paragraph";
import { Element } from "@prismicio/react";
import Card from "./atoms/Card";
import {
  Box,
  Flex,
  Grid,
  Spacer,
  VStack,
  useBreakpointValue
} from "@chakra-ui/react";
import { getTemplateColumns } from "./utils";
import { clamp } from "../utils";
import CardMobile from "./atoms/CardMobile";

const DEFAULT_INDEX_IF_NO_CMS_VALUE = -1;

const BlockStretchableCards: FC<Gatsby.PageBlockStretchableCardsFragment> = ({
  primary,
  items
}) => {
  if (!primary) throw Error();

  const {
    content,
    default_stretched_image_index: defaultStretchedImageIndex
  } = primary;

  const numberItems = items?.length || 0;
  const defaultImageIndex = !defaultStretchedImageIndex || defaultStretchedImageIndex === 0
    ? DEFAULT_INDEX_IF_NO_CMS_VALUE
    : clamp(0, numberItems - 1, DEFAULT_INDEX_IF_NO_CMS_VALUE, defaultStretchedImageIndex - 1);

  const isMobile = useBreakpointValue({
    base: true,
    xs: true,
    sm: true,
    md: false,
    lg: false,
    xl: false,
    "2xl": false,
    "3xl": false,
  });

  const [expandedItemsIndexes, setExpandedItemsIndexes] = useState<number[]>([defaultImageIndex]);

  const setItemsIndexes = (index: number) => {
    const newExpandedItemsIndexes = [index];
    setExpandedItemsIndexes(newExpandedItemsIndexes);
  };

  const resetItemsIndexes = () => {
    setExpandedItemsIndexes([defaultImageIndex]);
  };

  const expandedItemIndex = expandedItemsIndexes?.[0] >= 0 ? expandedItemsIndexes?.[0] : defaultImageIndex;

  return (
    <BlockContainer>
      <VStack w="full">
        <Box
          textAlign="left"
          w="full"
        >
          <Renderer
            field={content}
            overrides={{
              [Element.heading2]: (args) =>
                heading2({
                  ...args,
                  overrideProps: {
                    fontSize: { base: "font-30", md: "48px" },
                    lineHeight: 1.2,
                    marginBottom: "space-8",
                  },
                }),
              [Element.paragraph]: (args) =>
                paragraph({
                  ...args,
                  overrideProps: {
                    display: { base: "block", md: "none" }
                  },
                }),
            }}
          />
          <Flex>
            <Box
              display={{ base: "none", md: "block" }}
              width="full"
            >
              <Renderer
                field={content}
                overrides={{
                  [Element.heading1]: (args) =>
                    heading2({
                      ...args,
                      overrideProps: {
                        display: "none"
                      },
                    }),
                  [Element.heading2]: (args) =>
                    heading2({
                      ...args,
                      overrideProps: {
                        display: "none"
                      },
                    }),
                  [Element.heading3]: (args) =>
                    heading2({
                      ...args,
                      overrideProps: {
                        display: "none"
                      },
                    }),
                  [Element.paragraph]: (args) =>
                    paragraph({
                      ...args,
                      overrideProps: {
                        fontSize: { base: "font-16", md: "font-20" },
                        lineHeight: "base",
                      },
                    }),
                }}
              />
            </Box>
            <Spacer
              display={{ base: "block", md: "none" }}
            />
          </Flex>
        </Box>
        <Grid
          width='full'
          gridTemplateColumns={
            {
              base: "repeat(2, 1fr)",
              md: getTemplateColumns(expandedItemIndex, numberItems, "base"),
              lg: getTemplateColumns(expandedItemIndex, numberItems, "lg"),
              "xl": getTemplateColumns(expandedItemIndex, numberItems, "xl"),
              "2xl": getTemplateColumns(expandedItemIndex, numberItems, "2xl"),

            }
          }
          gridTemplateRows="1"
          gridColumnGap="space-16"
          gridRowGap={{ base: "space-24", md: 0 }}
          transition="grid linear 750ms"
        >
          {items?.length ? items.map((item, index: number) => {
            if (!item) {
              return null;
            }

            const backgroundImageUrl = item.background_image?.document?.data?.image_file?.gatsbyImageData?.images?.fallback?.src;

            if (isMobile) {
              return (
                <CardMobile
                  item={item}
                  key={`${item.title}-${item.description}-${backgroundImageUrl}`}
                />
              );
            }

            return (
              <Card
                item={item}
                index={index}
                key={`${item.title}-${item.description}-${backgroundImageUrl}`}
                expandedItemsIndexes={expandedItemsIndexes}
                onMouseEnter={() => setItemsIndexes(index)}
                onMouseLeave={() => resetItemsIndexes()}
              />
            );
          }) : null}
        </Grid>
      </VStack>
    </BlockContainer>
  );
};

export default BlockStretchableCards;
