import {
  Center,
  Heading,
  SimpleGrid,
  Text,
  VStack,
  Stack,
  Box,
  Container,
  Spacer
} from "@chakra-ui/react";

import { FC } from "react";
import { BlockContainer } from "src/atoms";
import Renderer from "src/lib/renderer";
import { Element } from "@prismicio/react";
import { hyperlink } from "src/lib/renderer/elements/hyperlink";
import { isValidElement } from "react";
import { heading2 } from "src/lib/renderer/elements/headings";
import paragraph from "src/lib/renderer/elements/paragraph";

const BlockSteps: FC<Gatsby.PageBlockStepsFragment> = ({ primary, items }) => {
  if (!primary || !items) throw Error();

  const { buttons, content, background_color: backgroundColor } = primary;

  const hasYellowBg = backgroundColor === "yellow";
  const hasGreyBg = backgroundColor === "grey";
  const hasColoredBg = hasYellowBg || hasGreyBg;

  return (
    <BlockContainer
      wrapperContainerProps={{
        size: hasColoredBg ? "block" : "full",
        paddingY: {
          base: "0",
          md: "space-60",
        },
        paddingX: {
          base: 0,
          md: "space-58",
          "2xl": 0,
        },
      }}
      marginY={{ base: "0", md: hasColoredBg ? "space-20" : "0"}}
      pt={{ base: "space-40", md: "space-60" }}
      pb={{ base: "space-70", md: "space-60" }}
      backgroundColor={{ base: "white", md: 
        hasColoredBg ? (hasYellowBg ? "yellow-0" : "#F5F5F5") : "white"
      }}
      borderRadius={{ base: "0", md: hasColoredBg ? "lg" : "0" }}
    >
      <VStack
        marginY={{ base: 0, md: "space-8" }}
        marginX={{ base: 0, md: "space-8" }}
        spacing={{ base: "space-32", md: "space-80" }}
      >
        <Container w="full" variant="heading">
          <VStack
            direction="column"
            align={{ base: "flex-start", md: "center" }}
            spacing="space-16"
            maxWidth={{ base: "100%", lg: "75%" }}
            minWidth={{ base: "75%", md: "100%", lg: "75%" }}
            margin={{ base: "0", md: "0 auto" }}
            textAlign={{ base: "left", md: "center" }}
          >
            <Renderer
              field={content}
              overrides={{
                [Element.heading2]: (args) =>
                  heading2({
                    ...args,
                    overrideProps: {
                      marginBottom: { base: 0, md: 0 },
                      fontSize: { base: "font-30", md: "font-34", lg: "48px" },
                      lineHeight: 1.2
                    },
                  }),
                [Element.paragraph]: (args) =>
                  paragraph({
                    ...args,
                    overrideProps: {
                      variant: "subtitle",
                      _last: {
                        marginBottom: 0,
                      },
                    },
                  })
              }}
            />
          </VStack>
        </Container>

        <SimpleGrid
          columns={{ base: 1, md: items?.length > 4 ? 4 : items.length }}
          spacing={{ base: "space-32", md: "space-80" }}
          w={{ base: "full", md: "auto" }}
        >
          {items.map((step, index) => {
            if (!step) return null;

            return (
              <VStack
                key={step.label}
                align="flex-start"
                textAlign={{ base: "left", md: "center" }}
                maxW={{ base: "unset", md: "322px" }}
                marginTop={{ base: "space-8", md: "space-0" }}
                letterSpacing={0}
              >
                <Stack
                  direction={{ base: "row", md: "column" }}
                  spacing={{ base: "space-16", md: "space-32" }}
                  align={{ base: "flex-start" }}
                >
                  <Center
                    borderRadius="lg"
                    bg={{ base: "grey-0", md: hasYellowBg ? "white" : "grey-0" }}
                    color={{ base: "white", md: hasYellowBg ? "grey-0" : "white" }}
                    fontSize={{ base: "font-16", md: "font-18" }}
                    justify="center"
                    lineHeight="short"
                    w={{ base: "53px", md: "53px" }}
                    h={{ base: "53px", md: "53px" }}
                    margin={{ md: "auto" }}
                  >
                    {`0${index + 1}`}
                  </Center>
                  <Box flex="1">
                    <Heading
                      as="h3"
                      fontFamily="body"
                      fontWeight="normal"
                      fontSize="font-24"
                      lineHeight={1.2}
                      mb={{ base: "space-8", md: "space-16" }}
                      letterSpacing={0}
                    >
                      {step.label}
                    </Heading>
                    <Text
                      as="div"
                      fontWeight="normal"
                      fontSize={{ base: "font-16", md: "font-18" }}
                      lineHeight="base"
                      letterSpacing={0}
                    >
                      {step.description}
                    </Text>
                  </Box>
                </Stack>
                <Spacer
                  display={{ base: "block", md: "none" }}
                  borderBottom={index === items?.length - 1 ? "0" : "1px solid"}
                  paddingTop={index === items?.length - 1 ? "space-24" : "space-32"}
                />
              </VStack>
            );
          })}
        </SimpleGrid>

        <Stack
          w="full"
          direction={{ base: "column", md: "row" }}
          spacing={{ base: "0", md: "space-40" }}
          flexFlow={{ base: "column-reverse", md: "row" }}
          justify="center"
          align="center"
          mt={{ base: "0 !important", md: "80px !important" }}
          sx={{
            ".chakra-button": {
              width: { base: "100%", md: "auto" },
              marginBottom: "18px",
            },
            ".btn-secondary": {
              marginTop: { base: "18px", md: 0 },
              width: "auto",
            },
            ".chakra-button:last-of-type": {
              marginBottom: 0,
            },
            p: {
              marginBottom: 0,
              w: { base: "full", md: "auto" },
            },
          }}
        >
          <Renderer
            overrides={{
              [Element.hyperlink]: (args) => {
                const isSecondaryButton = args?.children.some(
                  (child) =>
                    isValidElement(child) &&
                    child.props.className === "button-secondary",
                );

                return hyperlink({
                  ...args,
                  hasArrow: isSecondaryButton ? false : undefined,
                  overrideProps: isSecondaryButton ? {
                    borderBottomWidth: { base: "2px", md: hasColoredBg ? "2px" : "1px" },
                    paddingBottom: "space-4",
                    fontWeight: { base: "normal", md: "medium" },
                    fontSize: { base: "font-18", md: "font-15" },
                    lineHeight: { base: 1.56, md: 1.2 }
                  } : {
                    fontWeight: { base: "normal", md: "medium" },
                    paddingX: { base: "space-16", md: "space-24" },
                    paddingY: { base: "space-14", md: "space-16" },
                    fontSize: { base: "font-18", md: "font-15" },
                    lineHeight: { base: 1.56, md: 1.2 }
                  },
                });
              },
            }}
            field={buttons}
          />
        </Stack>
      </VStack>
    </BlockContainer>
  );
};

export default BlockSteps;
