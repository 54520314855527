import { StackProps, Box, Link } from "@chakra-ui/react";
import { FC, useContext, useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";

import Renderer from "src/lib/renderer";
import { Element } from "@prismicio/react";
import { heading3 } from "src/lib/renderer/elements/headings";
import paragraph from "src/lib/renderer/elements/paragraph";
import FilterableContext from "src/slices/BlockFilterableList/context";

const Card: FC<
  StackProps & { item: Gatsby.PageBlockFeatureCarouselItemFragment }
> = ({ item, ...rest }) => {
  const [category, setCategory] = useState("");

  const { filters, setFilters } = useContext(FilterableContext);

  if (!item) {
    return null;
  }

  const hasDescription =
    item.rich_description && item.rich_description?.text !== "";
  const hasButton = item.button && item.button?.text !== "";

  return (
    <Link
      display="block"
      bg="#F5F5F5"
      borderRadius="lg"
      key={category}
      textAlign="left"
      onClick={() => {
        setFilters({
          ...filters,
          query: "",
          category,
        });
      }}
      p={{ base: "space-24", md: "space-32"}} 
      transitionProperty="all"
      transitionDuration="300ms"
      transitionTimingFunction="ease-out"
      _hover={{
        transform: { md: "translateY(-8px)" },
      }}
    >
      <Box
        as={GatsbyImage}
        image={item.image?.document?.data?.image_file?.gatsbyImageData}
        alt={item.image?.document?.data?.image_file?.alt ?? ""}
        borderRadius={{ base: "14px", md: "lg" }}
        h={{ base: "168px", md: "233px"}}
        mb={{ base: "space-24", md: "space-40"}}
        w="full"
      />

      {hasDescription ? (
        <Renderer
          overrides={{
            [Element.heading3]: (args: any) => {
              setCategory(args.content)
              return heading3({
                ...args,
                overrideProps: {
                  fontFamily: "body",
                  marginBottom: "space-16",
                  variant: "subtitle",
                  fontSize: { base: "font-20", md: "font-22" },
                  lineHeight: "none",
                },
              })
            },
            [Element.paragraph]: (args: any) =>
              paragraph({
                ...args,
                overrideProps: {
                  marginBottom: 0,
                  fontSize: "font-15",
                  lineHeight: "24px",
                },
              }),
          }}
          field={item.rich_description}
        />
      ) : null}

      {hasButton ? (
        <Renderer
          field={item.button}
          overrides={{
            [Element.paragraph]: (args) =>
              paragraph({
                ...args,
                overrideProps: {
                  marginTop: { base: "space-24", md: "space-40" }, 
                  marginBottom: 0,
                  color: { base: "grey-0", md: "grey-1" },
                },
              }),
          }}
        />
      ) : null}

    </Link>
  );
};

export default Card;

