import { FC } from "react";
import { VStack, Text, Box, Link, HStack } from "@chakra-ui/react";
import { GatsbyImage } from "gatsby-plugin-image";
import { SvgOrImg } from "src/components/SvgOrImg";

const CardMobile: FC<{
  item: NonNullable<Gatsby.PageBlockStretchableCardsFragment["items"]>[number]
}> = ({
  item,
}) => {
  if (!item) {
    return null;
  }

  const {
    title,
    background_image: backgroundImage,
    link,
    badge_label,
    badge_icon
  } = item;

  return (
    <VStack
      as={Link}
      display="flex"
      href={link?.url}
      spacing="space-16"
      w="full"
      alignItems="flex-start"
    >
      <Box
        as={GatsbyImage}
        image={backgroundImage?.document?.data?.image_file?.gatsbyImageData}
        alt={title}
        w="100%"
        borderRadius="lg"
        sx={{
          img: {
            aspectRatio: "1/1"
          }
        }}
      />

      <VStack
        alignItems="flex-start"
        spacing="space-4"
      >
        <Text
          fontSize="font-20"
          lineHeight="base"
          textAlign="left"
        >
          {title}
          <Text
            as="span"
            marginLeft="space-8"
          >
            →
          </Text>
        </Text>

        {badge_label ? (
          <HStack
            alignItems="flex-start"
            spacing="space-4"
            paddingX="space-8"
            paddingY="space-6"
            bg="#8BC7FF"
            borderRadius="md"
          >
            <Box mt="-1px">
              <SvgOrImg
                additionalBoxProps={{
                  maxW: { base: "16px"},
                  maxH: { base: "16px"}
                }}
                imageFile={badge_icon?.document?.data?.image_file}
                size={10}
              />
            </Box>
            <Text
              fontSize="font-10"
              lineHeight="none"
              color="grey-0"
            >
              {badge_label}
            </Text>
          </HStack>
        ) : null}
      </VStack>
    </VStack>
  );
};

export default CardMobile;
